import { ApolloError, NormalizedCacheObject } from '@apollo/client';
import {
	CondoleancePageSlugFieldsFragment,
	EfroOostNewsArticleFieldsFragment,
	EfroOostPageSlugFieldsFragment,
	GeolinkPageSlugFieldsFragment,
	GetCondoleancePageQuery,
	GetEfroOostNewsDetailQuery,
	GetEfroOostPageQuery,
	GetGeoLinkPageQuery,
	GetNewsDetailQuery,
	GetOpOostNewsDetailQuery,
	GetOpOostInConvoWithDetailQuery,
	GetOpOostPageQuery,
	GetPageQuery,
	GetPermitDetailQuery,
	GetPressReleaseDetailQuery,
	GetProjectDetailQuery,
	GetRegioExpresNewsDetailQuery,
	GetRegioExpresPageQuery,
	GetRoadMaintenanceDetailQuery,
	GetSubsidyDetailQuery,
	GetThemeDetailQuery,
	NewsArticleFieldsFragment,
	OpOostInConvoWithArticleFieldsFragment,
	OpOostNewsArticleFieldsFragment,
	OpOostPageSlugFieldsFragment,
	PageSlugFieldsFragment,
	PermitFieldsFragment,
	PressReleaseFieldsFragment,
	ProjectFieldsFragment,
	RegioExpresNewsArticleFieldsFragment,
	RegioExpresPageSlugFieldsFragment,
	RoadMaintenanceFieldsFragment,
	SubsidyFieldsFragment,
	ThemeFieldsFragment,
	RegionFieldsFragment,
	GetRegionDetailQuery,
	GetEfroOostInConvoWithDetailQuery,
	GetRegioExpresInConvoWithDetailQuery,
	EfroOostInConvoWithArticleFieldsFragment,
	RegioExpresInConvoWithArticleFieldsFragment,
	GetInConvoWithDetailQuery,
	InConvoWithArticleFieldsFragment
} from 'api/graphql-types';
import { GraphQLError } from 'graphql';

export interface IUsePageProtectionPageResponse {
	props: {
		apolloState: NormalizedCacheObject;
		data:
			| GetCondoleancePageQuery
			| GetEfroOostPageQuery
			| GetEfroOostNewsDetailQuery
			| GetGeoLinkPageQuery
			| GetOpOostPageQuery
			| GetOpOostNewsDetailQuery
			| GetInConvoWithDetailQuery
			| GetOpOostInConvoWithDetailQuery
			| GetEfroOostInConvoWithDetailQuery
			| GetRegioExpresInConvoWithDetailQuery
			| GetRegioExpresPageQuery
			| GetRegioExpresNewsDetailQuery
			| GetPageQuery
			| GetNewsDetailQuery
			| GetPressReleaseDetailQuery
			| GetProjectDetailQuery
			| GetSubsidyDetailQuery
			| GetThemeDetailQuery
			| GetPermitDetailQuery
			| GetRoadMaintenanceDetailQuery
			| GetRegionDetailQuery
			| null;
		error: ApolloError | GraphQLError[] | null;
	};
}

export type IUsePageProtectionPageData =
	| CondoleancePageSlugFieldsFragment
	| EfroOostPageSlugFieldsFragment
	| EfroOostNewsArticleFieldsFragment
	| GeolinkPageSlugFieldsFragment
	| OpOostPageSlugFieldsFragment
	| OpOostNewsArticleFieldsFragment
	| InConvoWithArticleFieldsFragment
	| OpOostInConvoWithArticleFieldsFragment
	| EfroOostInConvoWithArticleFieldsFragment
	| RegioExpresInConvoWithArticleFieldsFragment
	| RegioExpresPageSlugFieldsFragment
	| RegioExpresNewsArticleFieldsFragment
	| PageSlugFieldsFragment
	| NewsArticleFieldsFragment
	| PressReleaseFieldsFragment
	| ProjectFieldsFragment
	| SubsidyFieldsFragment
	| ThemeFieldsFragment
	| PermitFieldsFragment
	| RoadMaintenanceFieldsFragment
	| RegionFieldsFragment
	| null;

export const mapPageData = (data?: IUsePageProtectionPageResponse): IUsePageProtectionPageData | undefined => {
	const object = data && data.props && data.props.data;

	if (!object) return undefined;

	if ('condoleancePageBySlug' in object) {
		return object.condoleancePageBySlug?.data?.attributes;
	}

	if ('efroOostNewsarticleBySlug' in object) {
		return object.efroOostNewsarticleBySlug?.data?.attributes;
	}

	if ('efroOostPageBySlug' in object) {
		return object.efroOostPageBySlug?.data?.attributes;
	}

	if ('geolinkPageBySlug' in object) {
		return object.geolinkPageBySlug?.data?.attributes;
	}

	if ('opOostNewsarticleBySlug' in object) {
		return object.opOostNewsarticleBySlug?.data?.attributes;
	}

	if ('inConvoWithArticleBySlug' in object) {
		return object.inConvoWithArticleBySlug?.data?.attributes;
	}

	if ('opOostInConvoWithArticleBySlug' in object) {
		return object.opOostInConvoWithArticleBySlug?.data?.attributes;
	}

	if ('efroOostInConvoWithArticleBySlug' in object) {
		return object.efroOostInConvoWithArticleBySlug?.data?.attributes;
	}

	if ('regioExpresInConvoWithArticleBySlug' in object) {
		return object.regioExpresInConvoWithArticleBySlug?.data?.attributes;
	}

	if ('opOostPageBySlug' in object) {
		return object.opOostPageBySlug?.data?.attributes;
	}

	if ('regioExpresNewsarticleBySlug' in object) {
		return object.regioExpresNewsarticleBySlug?.data?.attributes;
	}

	if ('regioExpresPageBySlug' in object) {
		return object.regioExpresPageBySlug?.data?.attributes;
	}

	if ('newsarticleBySlug' in object) {
		return object.newsarticleBySlug?.data?.attributes;
	}

	if ('pageBySlug' in object) {
		return object.pageBySlug?.data?.attributes;
	}

	if ('pressReleaseBySlug' in object) {
		return object.pressReleaseBySlug?.data?.attributes;
	}

	if ('projectBySlug' in object) {
		return object.projectBySlug?.data?.attributes;
	}

	if ('subsidyBySlug' in object) {
		return object.subsidyBySlug?.data?.attributes;
	}

	if ('themeBySlug' in object) {
		return object.themeBySlug?.data?.attributes;
	}

	if ('permitBySlug' in object) {
		return object.permitBySlug?.data?.attributes;
	}

	if ('roadMaintenanceBySlug' in object) {
		return object.roadMaintenanceBySlug?.data?.attributes;
	}

	return undefined;
};

export default mapPageData;
