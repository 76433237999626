import { useEffect, useState } from 'react';
import client from 'api/apollo-client';

import { getServerPageGetPassword } from 'api/graphql-helper';
import { PasswordPageFieldsFragment } from 'api/graphql-types';
import { BaseModule } from 'modules/base-module/base-module';
import { ModuleBuilder } from 'modules/module-builder/module-builder';
import IntroHeader, { IModule } from 'modules/intro-header/intro-header';

export interface IPasswordLayout {
	locale?: string;
	slug?: string;
	children?: React.JSX.Element | React.JSX.Element[];
}

export const PasswordLayout = ({ locale, slug, children }: IPasswordLayout): React.JSX.Element => {
	const [pageData, setPageData] = useState<PasswordPageFieldsFragment | undefined>();

	const getPageData = async (): Promise<void> => {
		const results = await getServerPageGetPassword({ variables: { locale } }, client);
		const attributes = results?.props?.data?.passwordPage?.data?.attributes;
		setPageData(attributes);
	};

	useEffect(() => {
		getPageData();
	}, []);

	return (
		<>
			{pageData?.header && (
				<BaseModule
					defaultModuleOptions={{
						backgroundColor: pageData.header.backgroundColor || 'white',
						padding: 'none'
					}}
				>
					<IntroHeader module={pageData.header as IModule} fullPath={slug || '/'} />
				</BaseModule>
			)}
			{children}
			{pageData?.modules && <ModuleBuilder modules={pageData.modules} fullPath={slug || '/'} />}
		</>
	);
};

export default PasswordLayout;
