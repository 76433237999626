import * as React from 'react';
import { Icon } from 'components/icon/icon';
import Tag from 'public/assets/icons/tag.svg';
import { ITag } from '../../modules/intro-header/intro-header';

import styles from './tags.module.scss';

export interface ITagsProps {
	tags: ITag[];
}

const Tags = (props: ITagsProps): React.JSX.Element => {
	if (props.tags?.length < 1) {
		return (
			<div className={styles.tags}>
				<span className={styles.tag} />
			</div>
		);
	}

	return (
		<div className={styles.tags}>
			<Icon customIcon={<Tag />} size="24px" />
			{props.tags?.map((tag: ITag, i: number) => {
				return (
					<span key={tag?.id} className={styles.tag}>
						{` ${tag?.name}${i + 1 < props.tags?.length ? ', ' : ''} `}
					</span>
				);
			})}
		</div>
	);
};

export default Tags;
