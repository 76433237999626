import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { PublicationState } from 'api/graphql-types';
import { IPasswordProtectionProps, PasswordProtection } from 'modules/password-protection';
import mapPageData, { IUsePageProtectionPageData } from './map-page-data';

export interface IUsePageProtectionVariables {
	slug: string;
	locale?: string;
	publicationState: PublicationState;
}

export interface IUserPageProtectionProps {
	pageData: IUsePageProtectionPageData;
	pageQuery: (props?: any) => Promise<any>;
}

export interface IUsePageProtectionReturn {
	page: IUsePageProtectionPageData;
	PasswordModule: React.FC;
}

export type IUserPageProtection = (props: IUserPageProtectionProps) => IUsePageProtectionReturn;

const usePageProtection: IUserPageProtection = ({ pageData, pageQuery }) => {
	const { formatMessage } = useIntl();
	const [page, setPage] = useState<IUsePageProtectionPageData>(pageData);
	const [password, setPassword] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');

	const onPasswordSubmit = (input?: string) => {
		if (input && window) {
			setPassword(window.atob(input));
		}
	};

	const getPageData = async (): Promise<void> => {
		setIsLoading(true);
		const pageResult = await pageQuery();
		const data = mapPageData(pageResult);

		if (data) {
			setPage(data);
		} else {
			setErrorMessage(formatMessage({ id: 'modules.passwordProtection.error' }));
			setIsLoading(false);
		}
	};

	const PasswordModule = useMemo(() => {
		const Component: React.FC<IPasswordProtectionProps> = () => (
			<>
				<PasswordProtection errorMessage={errorMessage} isLoading={isLoading} onPasswordSubmit={onPasswordSubmit} />
			</>
		);
		return Component;
	}, [isLoading]);

	useEffect(() => {
		if (password && password !== '') {
			getPageData();
		}
	}, [password]);

	useEffect(() => {
		setIsLoading(false);
	}, [page]);

	return {
		page,
		PasswordModule
	};
};

export default usePageProtection;
