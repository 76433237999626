import client, { httpLink, setPasswordProtectionToken } from 'api/apollo-client';
import { useIntl } from 'react-intl';
import dynamic from 'next/dynamic';

import { BaseModule } from 'modules/base-module/base-module';

import styles from './password-protection.module.scss';

const PasswordForm = dynamic(() => import('components/password-form/password-form'), { ssr: false });

export interface IPasswordProtectionProps {
	isLoading?: boolean;
	onPasswordSubmit?: (input: string) => void;
	errorMessage?: string;
}

export const PasswordProtection = ({
	isLoading,
	onPasswordSubmit,
	errorMessage
}: IPasswordProtectionProps): React.JSX.Element => {
	const intl = useIntl();

	const onSubmit = (password: string): void => {
		// eslint-disable-next-line unicorn/prefer-spread
		client.setLink(setPasswordProtectionToken(password).concat(httpLink));

		if (onPasswordSubmit) {
			onPasswordSubmit(password);
		}
	};

	return (
		<BaseModule
			defaultModuleOptions={{
				backgroundColor: 'white',
				padding: 'medium'
			}}
		>
			{errorMessage && <div className={styles.error}>{errorMessage}</div>}
			<PasswordForm intl={intl} onSubmit={onSubmit} isLoading={!!isLoading} />
		</BaseModule>
	);
};

export default PasswordProtection;
