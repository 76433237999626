import React from 'react';
import dynamic from 'next/dynamic';

import { BaseModule } from 'modules/base-module/base-module';

const BBVMSMediaPlayer = dynamic(() => import('modules/bbvms-mediaplayer/bbvms-mediaplayer'), { ssr: false });
const NewsOverview = dynamic(() => import('modules/news-overview/news-overview'));
const FeaturedNews = dynamic(() => import('modules/featured-news/featured-news'));
const FeaturedPages = dynamic(() => import('modules/featured-pages/featured-pages'));
const IntroHeader = dynamic(() => import('modules/intro-header/intro-header'));
const Categories = dynamic(() => import('modules/categories/categories'));
const Cta = dynamic(() => import('modules/cta/cta'));
const BodyTextTwoColumns = dynamic(() => import('modules/body-text/two-columns/body-text-two-columns'));
const BodyText = dynamic(() => import('modules/body-text/normal/normal-body-text'));
const PressReleaseOverview = dynamic(() => import('modules/press-release-overview/press-release-overview'));
const Downloads = dynamic(() => import('modules/downloads/downloads'));
const Linkbox = dynamic(() => import('modules/linkbox/linkbox'));
const ExpandedHeader = dynamic(() => import('modules/expanded-header/expanded-header'));
const Subjects = dynamic(() => import('modules/subjects/subjects'));
const Media = dynamic(() => import('modules/media/media'), {
	ssr: false
});
const Accordeon = dynamic(() => import('modules/accordeon/accordeon-module'));
const SubsidyOverview = dynamic(() => import('modules/subsidy-overview/subsidy-overview'));
const Highlight = dynamic(() => import('modules/highlight'));
const StepByStep = dynamic(() => import('modules/step-by-step/step-by-step-module'));
const SchedulingModule = dynamic(() => import('modules/scheduling-module/scheduling-module'));
const PermitsOverview = dynamic(() => import('modules/permits-overview/permits-overview'));
const RoadMaintenanceOverview = dynamic(() => import('modules/road-maintenance-overview'));
const Testimonial = dynamic(() => import('modules/testimonial'));
const Partners = dynamic(() => import('modules/partners'));
const TableModule = dynamic(() => import('modules/table-module/table-module'));
const EventOverview = dynamic(() => import('modules/events-overview'));
const ProjectOverview = dynamic(() => import('modules/projects-overview/projects-overview'));
const MapsFiguresOverview = dynamic(() => import('modules/maps-figures-overview/maps-figures-overview'));
const WobRequestOverview = dynamic(() => import('modules/wob-request-overview'));
const WooRequestOverview = dynamic(() => import('modules/woo-request-overview/woo-request-overview'));
const WooDocumentOverview = dynamic(() => import('modules/woo-document-overview/woo-document-overview'));
const InConvoWithOverview = dynamic(() => import('modules/in-convo-with-overview/in-convo-with-overview'));
const HomepageHeader = dynamic(() => import('modules/homepage-header/homepage-header'));
const Share = dynamic(() => import('modules/share/share'), { ssr: false });
const PhotoAlbum = dynamic(() => import('modules/photo-album'));
const VacanciesOverview = dynamic(() => import('modules/vacancies-overview/vacancies-overview'));

export interface IModuleBuilderProps {
	modules?: any[] | null;
	fullPath: string;
}

const ModuleBuilder = (props: IModuleBuilderProps): React.JSX.Element => {
	if (!props.modules) return <></>;

	const modules = props.modules.filter((item) => item.__typename !== 'ComponentModulesSeo');

	return (
		<>
			{modules.map((module, index) => {
				if (module?.defaultModuleOptions) {
					return (
						<BaseModule defaultModuleOptions={module?.defaultModuleOptions} key={`module-${index}`}>
							{getModule(module, props.fullPath)}
						</BaseModule>
					);
				}

				return <React.Fragment key={`module-${index}`}>{getModule(module, props.fullPath)}</React.Fragment>;
			})}
		</>
	);
};

const getModule = (module: any, fullPath: string): React.JSX.Element => {
	// eslint-disable-next-line unicorn/prefer-module
	switch (module.__typename) {
		case 'ComponentModulesCta': {
			return <Cta module={module} />;
		}
		case 'ComponentModulesBodyText': {
			return <BodyText module={module} />;
		}
		case 'ComponentModulesHomepageHeader': {
			return <HomepageHeader module={module} />;
		}
		case 'ComponentModulesThemes':
		case 'ComponentModulesRegions': {
			return <Categories module={module} />;
		}
		case 'ComponentModulesIntroHeader': {
			return <IntroHeader module={module} fullPath={fullPath} />;
		}
		case 'ComponentModulesFeaturedNews': {
			return <FeaturedNews module={module} />;
		}
		case 'ComponentModulesFeaturedPages': {
			return <FeaturedPages module={module} />;
		}
		case 'ComponentModulesNewsOverview': {
			return <NewsOverview module={module} />;
		}
		case 'ComponentModulesEventOverview': {
			return <EventOverview module={module} />;
		}
		case 'ComponentModulesProjectOverview': {
			return <ProjectOverview module={module} />;
		}
		case 'ComponentModulesPressReleaseOverview': {
			return <PressReleaseOverview />;
		}
		case 'ComponentModulesBodyTextTwoColumn': {
			return <BodyTextTwoColumns module={module} />;
		}
		case 'ComponentModulesDownloads': {
			return <Downloads module={module} />;
		}
		case 'ComponentModulesLinkBox': {
			return <Linkbox module={module} />;
		}
		case 'ComponentModulesExpandedHeader': {
			return <ExpandedHeader module={module} fullPath={fullPath} />;
		}
		case 'ComponentModulesSubjects': {
			return <Subjects module={module} />;
		}
		case 'ComponentModulesMedia': {
			return <Media module={module} />;
		}
		case 'ComponentModulesAccordeon': {
			return <Accordeon module={module} />;
		}
		case 'ComponentModulesSubsidyOverview': {
			return <SubsidyOverview module={module} />;
		}
		case 'ComponentModulesHighlight': {
			return <Highlight module={module} />;
		}
		case 'ComponentModulesStepByStep': {
			return <StepByStep module={module} />;
		}
		case 'ComponentModulesScheduling': {
			return <SchedulingModule module={module} />;
		}
		case 'ComponentModulesTable': {
			return <TableModule module={module} />;
		}
		case 'ComponentModulesPermitsOverview': {
			return <PermitsOverview module={module} />;
		}
		case 'ComponentModulesRoadMaintenanceOverview': {
			return <RoadMaintenanceOverview module={module} />;
		}
		case 'ComponentModulesTestimonial': {
			return <Testimonial module={module} />;
		}
		case 'ComponentModulesPartners': {
			return <Partners module={module} />;
		}
		case 'ComponentModulesMapsFiguresOverview': {
			return <MapsFiguresOverview module={module} />;
		}
		case 'ComponentModulesWobRequestOverview': {
			return <WobRequestOverview module={module} />;
		}
		case 'ComponentModulesWooRequestOverview': {
			return <WooRequestOverview module={module} />;
		}
		case 'ComponentModulesWooDocumentOverview': {
			return <WooDocumentOverview module={module} />;
		}
		case 'ComponentModulesFeaturedNewsRegioExpres': {
			return <FeaturedNews module={module} />;
		}
		case 'ComponentModulesFeaturedNewsOpOost': {
			return <FeaturedNews module={module} />;
		}
		case 'ComponentModulesFeaturedNewsEfroOost': {
			return <FeaturedNews module={module} />;
		}
		case 'ComponentModulesInConvoWithOverview': {
			return <InConvoWithOverview module={module} />;
		}
		case 'ComponentModulesShare': {
			return <Share module={module} />;
		}
		case 'ComponentModulesPhotoAlbum': {
			return <PhotoAlbum module={module} />;
		}
		case 'ComponentModulesBbvmsMediaplayer': {
			return <BBVMSMediaPlayer module={module} />;
		}
		case 'ComponentModulesVacanciesOverview': {
			return <VacanciesOverview module={module} fullPath={fullPath} />;
		}
		default:
			return <>Module Not Found: {module.__typename}</>;
	}
};

export { ModuleBuilder };
