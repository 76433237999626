import clsx from 'clsx';
import { useAppSelector } from 'redux/store';
import { Breadcrumb } from 'components/breadcrumb/breadcrumb';
import { Enum_Componentmodulesintroheader_Backgroundcolor, IntroHeaderFieldsFragment } from 'api/graphql-types';
import { Typography } from 'components/typography';
import Image from 'components/image';
import Tags from 'components/tags/tags';

import styles from './intro-header.module.scss';

export interface ICover {
	url?: string;
	altText?: string;
}

export interface ITag {
	name: string;
	id: number;
	__typename: string;
}

export interface IModule extends IntroHeaderFieldsFragment {
	cover?: any;
	tags?: any[];
}

interface IHeaderProps {
	module?: IModule;
	fullPath: string;
	showBreadCrumbs?: boolean;
}

const IntroHeader = (props: IHeaderProps): React.JSX.Element => {
	const backgroundStyles = getBackgroundStyles(props.module?.backgroundColor);
	const showBreadcrumbs = props.showBreadCrumbs !== false;

	const { showNeutralStyle } = useAppSelector((state) => state.settings);

	return (
		<div className={clsx(styles.introHeader, backgroundStyles)}>
			<div className="container">
				<div className={styles.header}>
					<div className={styles.content}>
						{!showNeutralStyle && (
							<div className={styles.sidebar}>
								<div />
								<div />
								<div />
								<div />
							</div>
						)}

						<div className={clsx(styles.main, !props.module?.excerpt && styles.noDescription)}>
							{showBreadcrumbs && (
								<div className={styles.breadcrumb}>
									<Breadcrumb path={props.fullPath} />
								</div>
							)}
							<div className={styles.title}>
								<h1>{props.module?.title}</h1>
							</div>
							{props.module?.excerpt && (
								<div className={styles.excerpt}>
									<Typography tag="div" variant="h4">
										{props.module?.excerpt}
									</Typography>
									{props.module?.tags && <Tags tags={props.module?.tags} />}
								</div>
							)}
						</div>
					</div>
					{props.module?.cover?.data?.attributes && (
						<div className={clsx(styles.cover, !props.module.cover?.data?.attributes && styles.noCover)}>
							<Image
								attributes={props.module.cover?.data?.attributes}
								sizes="(max-width: 560px) 520px, (max-width: 1200px) 1185px, 35vw"
								fill
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const getBackgroundStyles = (background?: Enum_Componentmodulesintroheader_Backgroundcolor): string => {
	switch (background) {
		case 'white':
			return styles.backgroundWhite;
		case 'grey':
			return styles.backgroundGrey;
		default:
			return styles.backgroundWhite;
	}
};

export default IntroHeader;
