import { ILink } from 'api/types';
import clsx from 'clsx';
import { Link } from 'components/link/link';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import styles from './breadcrumb.module.scss';

export interface IBreadcrumbProps {
	path: string;
}

const Breadcrumb = (props: IBreadcrumbProps): React.JSX.Element => {
	const intl = useIntl();
	const [breadcrumbs, setBreadcrumbs] = useState<ILink[]>([]);

	useEffect(() => {
		const newBreadcrumbs = getBreadcrumbs(intl, props.path);

		setBreadcrumbs(newBreadcrumbs);
	}, [props.path]);

	return (
		<div className={clsx('rs_skip', styles.breadcrumbs)}>
			{breadcrumbs.map((breadcrumb, index) => (
				<span className={clsx(styles.breadcrumb, index < breadcrumbs.length - 1 && styles.link)} key={index}>
					{index < breadcrumbs.length - 1 ? (
						<Link variant="tertiary-dark" href={breadcrumb.href}>
							{breadcrumb.name}
						</Link>
					) : (
						breadcrumb.name
					)}
				</span>
			))}
		</div>
	);
};

export { Breadcrumb };

const getBreadcrumbs = (intl: IntlShape, path = ''): ILink[] => {
	const base: ILink = { href: '/', name: intl.formatMessage({ id: 'components.breadcrumb.home' }) };

	const splitPath = path.split('/').filter(Boolean);

	const items = splitPath.map((slug, index) => {
		const removedSlugs = splitPath.filter((_, slugIndex) => slugIndex <= index);

		const betterName = capitalize(slug.replaceAll('-', ' '));

		const href = removedSlugs.length > 0 ? `/${removedSlugs.join('/')}/` : '/';

		return { name: betterName, href };
	});

	return [base, ...items];
};
